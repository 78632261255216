<template>
  <div class="setting-client-passage style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="6">
          <div class="title-header">
            Gestion des clients passager
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="getClientlsLoading"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="3">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getClientIsError">
              <ul v-if="Array.isArray(getClientIsError)" class="mb-0">
                <li v-for="(e, index) in getClientIsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getClientIsError }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <div>
            <search
              v-if="checkPermission('GRCP')"
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
              classToUse="search-style"
            />
          </div>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GACP')"
                @click="openModalAjouterClient"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter un client passager</span>
          </v-tooltip>
        </div>
      </div>
    </div>

    <div class="table-gestion-content">
      <v-data-table
        v-model="selectedTable"
        :headers="computedFields"
        :items="getListClientPassages"
        :items-per-page="perPage"
        class="table-gestion-client-passage"
        hide-default-footer
        disable-sort
        :calculate-widths="false"
        :fixed-header="true"
        hide-action
        :no-data-text="
          getClientlsLoading
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <v-btn
              v-if="checkPermission('GMCP')"
              small
              class="block-icon-reglement-fac mr-2"
              @click.prevent.stop="showUpdateClient(item)"
              title="Modifier"
            >
              <font-awesome-icon icon="pencil-alt" />
            </v-btn>
            <v-btn
              v-if="checkPermission('GTCP')"
              small
              class="block-icon-reglement-fac mr-2"
              @click.prevent.stop="showTransfertClient(item)"
              title="Transfert du client vers la gestion des sociétés"
            >
              <font-awesome-icon icon="file-export"
            /></v-btn>
            <v-btn
              v-if="checkPermission('GSCP')"
              small
              class="block-icon-reglement-fac"
              @click.prevent.stop="showDeleteClient(item)"
              title="Supprimer"
            >
              <font-awesome-icon icon="trash" />
            </v-btn>
          </td>
        </template>
      </v-data-table>
      <div class="footer-style-table">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            v-model="perPage"
            :items="perPageList"
            @change="changePerPage"
            label="Eléments par page"
            outlined
            dense
            hide-details
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
      </div>
    </div>

    <!-- ADD MODAL CLIENT  -->
    <v-dialog
      v-model="addClientModal"
      max-width="920px"
      hide-overlay
      persistent
      scrollable
      content-class="custom-vuetify-dialog-gestion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter un client passager</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addClientModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  label="Nom de client *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.name"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  class="msg-error"
                  validate-on-blur
                  :rules="[v => !!v || 'Nom de client est obligatoire']"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="num_tva_siren"
                  v-model="configurationClientToAdd.siren_tva"
                  label=""
                  dense
                  outlined
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col
                v-if="
                  configurationClientToAdd &&
                    configurationClientToAdd.siren_tva === 'Numéro TVA'
                "
              >
                <v-text-field
                  label=""
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.tva_value"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="
                  configurationClientToAdd &&
                    configurationClientToAdd.siren_tva === 'SIREN'
                "
              >
                <v-text-field
                  label=""
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.siren_value"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="N° et nom de rue *"
                  v-model="configurationClientToAdd.adresse"
                  :persistent-placeholder="true"
                  required
                  rows="2"
                  :rules="[v => !!v || 'N° et nom de rue est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Code postal *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.code_postal"
                  required
                  :rules="[v => !!v || 'Code postal est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Ville *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.ville"
                  required
                  :rules="[v => !!v || 'Ville est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-select
                  :items="uniqueArrayOfCountries"
                  v-model="configurationClientToAdd.pays"
                  label="Pays *"
                  dense
                  outlined
                  required
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Pays est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  no-data-text="Aucune Pays trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nom"
                  item-value="nom"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Description additionnelle"
                  rows="2"
                  v-model="configurationClientToAdd.description"
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Siret"
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.siret"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Adresse Email *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.email"
                  required
                  :rules="[v => !!v || 'Adresse Email est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  @blur="checkFilialeMail(configurationClientToAdd.email)"
                  type="email"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="mails && mails.length">
                <v-menu
                  :close-on-content-click="false"
                  offset-y
                  right
                  v-if="mails && mails.length"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="block-icon-reglement-fac"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <font-awesome-icon
                        v-show="mails && mails.length"
                        icon="info"
                        class="mb-2"
                      />
                    </div>
                  </template>
                  <v-card>
                    <v-card-text class="body-card center-text">
                      <v-data-table
                        :headers="[{ value: 'name', text: 'Nom société' }]"
                        :items="mails"
                        hide-default-footer
                        hide-action
                        disable-filtering
                        disable-sort
                        disable-pagination
                        class="table-dialog"
                        item-class="text-center"
                      >
                        <template v-slot:[`item.value`]="item">
                          {{ item.name }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.telephone"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Téléphone portable *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToAdd.telephone_portable"
                  required
                  :rules="[v => !!v || 'Téléphone portable est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div class="card-style">
              <span class="title-card">Comptabilité</span>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToAdd.vente.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Journal achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToAdd.achat.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Client : Compte generale "
                    small
                    :persistent-placeholder="true"
                    v-model="configurationClientToAdd.customer"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Fournisseur : Compte generale "
                    small
                    :persistent-placeholder="true"
                    v-model="configurationClientToAdd.supplier"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToAdd.achat.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToAdd.achat.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToAdd.vente.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToAdd.vente.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="configurationClientToAdd.is_detailed"
                    color="#704ad1"
                    :value="true"
                    label="Hors taxes Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="configurationClientToAdd.is_detailed_ttc"
                    color="#704ad1"
                    :value="true"
                    label="TTC Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="configurationClientToAdd.is_detailed_reste_charge"
                    color="#704ad1"
                    :value="true"
                    label="Reste à charge défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleSubmitClient"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addClientModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END ADD MODAL CLIENT -->
    <!-- UPDATE MODAL CLIENT -->
    <v-dialog
      v-model="updateClientModal"
      max-width="920px"
      hide-overlay
      scrollable
      persistent
      content-class="custom-vuetify-dialog-gestion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier un client passager</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('updateClientModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text
          class="body-card center-text  mt-2"
          v-if="configurationClientToUpdate"
        >
          <v-form
            class="mt-2"
            ref="formUpdate"
            lazy-validation
            v-if="configurationClientToUpdate"
          >
            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  label="Nom de client *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.name"
                  dense
                  outlined
                  required
                  :rules="[v => !!v || 'Nom de client est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="num_tva_siren"
                  v-model="configurationClientToUpdate.siren_tva"
                  label=""
                  dense
                  outlined
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col
                v-if="
                  configurationClientToUpdate &&
                    configurationClientToUpdate.siren_tva === 'Numéro TVA'
                "
              >
                <v-text-field
                  label=""
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.tva_value"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="
                  configurationClientToUpdate &&
                    configurationClientToUpdate.siren_tva === 'SIREN'
                "
              >
                <v-text-field
                  label=""
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.siren_value"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="N° et nom de rue *"
                  v-model="configurationClientToUpdate.adresse"
                  :persistent-placeholder="true"
                  required
                  rows="2"
                  :rules="[v => !!v || 'N° et nom de rue est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Code postal *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.code_postal"
                  required
                  :rules="[v => !!v || 'Code postal est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Ville *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.ville"
                  required
                  :rules="[v => !!v || 'Ville est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-select
                  :items="uniqueArrayOfCountries"
                  v-model="configurationClientToUpdate.pays"
                  label="Pays *"
                  dense
                  outlined
                  required
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Pays est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nom"
                  item-value="nom"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucune pays trouvé"
                ></v-select
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Description additionnelle"
                  rows="2"
                  v-model="configurationClientToUpdate.description"
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Siret"
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.siret"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Adresse Email *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.email"
                  required
                  :rules="[v => !!v || 'Adresse Email est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  @blur="checkFilialeMail(configurationClientToUpdate.email)"
                  type="email"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="mails && mails.length">
                <v-menu
                  :close-on-content-click="false"
                  offset-y
                  right
                  v-if="mails && mails.length"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="block-icon-reglement-fac"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <font-awesome-icon
                        v-show="mails && mails.length"
                        icon="info"
                        class="mb-2"
                      />
                    </div>
                  </template>
                  <v-card>
                    <v-card-text class="body-card center-text">
                      <v-data-table
                        :headers="[{ value: 'name', text: 'Nom société' }]"
                        :items="mails"
                        hide-default-footer
                        hide-action
                        disable-filtering
                        disable-sort
                        disable-pagination
                        class="table-dialog"
                        item-class="text-center"
                      >
                        <template v-slot:[`item.value`]="item">
                          {{ item.name }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.telephone"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Téléphone portable *"
                  :persistent-placeholder="true"
                  v-model="configurationClientToUpdate.telephone_portable"
                  required
                  :rules="[v => !!v || 'Téléphone portable est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div class="card-style">
              <span class="title-card">Comptabilité</span>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToUpdate.vente.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Journal achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToUpdate.achat.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Client : Compte generale "
                    small
                    :persistent-placeholder="true"
                    v-model="configurationClientToUpdate.customer"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Fournisseur : Compte generale "
                    small
                    :persistent-placeholder="true"
                    v-model="configurationClientToUpdate.supplier"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToUpdate.achat.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToUpdate.achat.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToUpdate.vente.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToUpdate.vente.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="configurationClientToUpdate.is_detailed"
                    color="#704ad1"
                    :value="true"
                    label="Hors taxes Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="configurationClientToUpdate.is_detailed_ttc"
                    color="#704ad1"
                    :value="true"
                    label="TTC Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="
                      configurationClientToUpdate.is_detailed_reste_charge
                    "
                    color="#704ad1"
                    :value="true"
                    label="Reste à charge défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleUpdateClient"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('updateClientModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END UPDATE MODAL CLIENT -->
    <!-- DELETED MODAL CLIENT -->
    <v-dialog
      v-model="deletedClientModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer un client passager</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deletedClientModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous sur de vouloir supprimer cette client
              <span class="bold-text">{{
                configurationClientToDeleted
                  ? configurationClientToDeleted.name
                  : ''
              }}</span>
              ?
            </p>
          </div>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleDeleteConfClient"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('deletedClientModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END DELETED MODAL CLIENT -->
    <!-- ADD TRANSFERT CLIENT VERS LA GESTIONS DES SOCIETES MODAL -->
    <v-dialog
      v-model="transfetClientModal"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card v-if="configurationClientToTransfertClient != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Transfert du client vers la gestion des sociétés</v-label
          >

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('transfetClientModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text
          class="body-card center-text  mt-2"
          v-if="configurationClientToTransfertClient != null"
        >
          <v-form ref="formTransfert" lazy-validation>
            <v-row class="row-flex mt-2">
              <v-col>
                <v-select
                  placeholder=""
                  v-model="configurationClientToTransfertClient.type"
                  disabled
                  :items="optionsTypeSociete"
                  label="Type de société"
                  :persistent-placeholder="true"
                  no-data-text="Aucun Type de société trouvée"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col class="config-check-societe">
                <v-checkbox
                  label="Configure une société"
                  v-model="configurationClientToTransfertClient.in_configs"
                  color="#704ad1"
                  :value="true"
                  :true-value="'oui'"
                  :false-value="'non'"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="row-flex">
              <v-col class="config-check-societe" cols="2">
                <v-checkbox
                  label="Support"
                  v-model="
                    configurationClientToTransfertClient.is_societe_support
                  "
                  @change="onChangeSupport"
                  color="#704ad1"
                  :value="true"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                v-if="
                  configurationClientToTransfertClient.is_societe_support ==
                    true
                "
              >
                <v-select
                  placeholder=""
                  :items="getTypeSocieteSupportTh"
                  v-model="
                    configurationClientToTransfertClient.type_societe_support
                  "
                  required
                  :rules="[
                    v => !!v || 'Type de société support est obligatoire'
                  ]"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Type de société support"
                  :persistent-placeholder="true"
                  item-text="text"
                  no-data-text="Aucun Type de société support trouvée"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Nom de société *"
                  :rules="[v => !!v || 'Nom de société est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  v-model="configurationClientToTransfertClient.name"
                  required
                  :auto-focus="false"
                  outlined
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  placeholder=""
                  :items="getTypeSociete"
                  v-model="configurationClientToTransfertClient.type_societe"
                  class="select-menu"
                  label="Type de société"
                  :persistent-placeholder="true"
                  item-text="type"
                  item-value="type"
                  no-data-text="Aucun Type de société trouvée"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-input-colorpicker
                  label="Couleur"
                  colorpick-eyedropper-active="false"
                  class="color_picker_width ml-2"
                  v-model="configurationClientToTransfertClient.couleur"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Adresse *"
                  v-model="configurationClientToTransfertClient.adresse"
                  :persistent-placeholder="true"
                  rows="3"
                  :auto-focus="false"
                  required
                  :rules="[v => !!v || 'Adresse obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  placeholder=""
                  :items="
                    computedDaysOfMonth(
                      configurationClientToTransfertClient.start_month
                    )
                  "
                  v-model="configurationClientToTransfertClient.start_day"
                  required
                  :rules="[v => !!v || 'Jour début exercice est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Jour début exercice"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  no-data-text="Aucun option trouvée"
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  placeholder=""
                  :items="month_list"
                  v-model="configurationClientToTransfertClient.start_month"
                  required
                  :rules="[v => !!v || 'Mois début exercice est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Mois début exercice"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun option trouvée"
                >
                </v-select>
              </v-col>
              <!-- <v-col>
                <v-select
                  placeholder=""
                  :items="computedListeYear"
                  v-model="configurationClientToTransfertClient.start_year"
                  required
                  :rules="[v => !!v || 'Année début exercice est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Année début exercice"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun option trouvée"
                >
                </v-select>
              </v-col> -->
              <!-- END -->
              <v-col>
                <v-select
                  placeholder=""
                  :items="
                    computedDaysOfMonth(
                      configurationClientToTransfertClient.end_month
                    )
                  "
                  v-model="configurationClientToTransfertClient.end_day"
                  required
                  :rules="[v => !!v || 'Jour fin exercice est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Jour fin exercice"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun option trouvée"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  placeholder=""
                  :items="month_list"
                  v-model="configurationClientToTransfertClient.end_month"
                  required
                  :rules="[v => !!v || 'Mois fin exercice est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Mois fin exercice"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun option trouvée"
                >
                </v-select>
              </v-col>
              <!-- <v-col>
                <v-select
                  placeholder=""
                  :items="computedListeYear"
                  v-model="configurationClientToTransfertClient.end_year"
                  required
                  :rules="[v => !!v || 'Année fin exercice est obligatoire']"
                  validate-on-blur
                  class="select-menu msg-error"
                  label="Année fin exercice"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun option trouvée"
                >
                </v-select>
              </v-col> -->
            </v-row>
            <div class="card-style">
              <span class="title-card">Comptabilité</span>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToTransfertClient.vente.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Journal achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="configurationClientToTransfertClient.achat.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Client : Compte generale "
                    small
                    :persistent-placeholder="true"
                    v-model="configurationClientToTransfertClient.customer"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Fournisseur : Compte generale "
                    small
                    :persistent-placeholder="true"
                    v-model="configurationClientToTransfertClient.supplier"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="
                      configurationClientToTransfertClient.achat.prestation
                    "
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="
                      configurationClientToTransfertClient.achat.marchandise
                    "
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="
                      configurationClientToTransfertClient.vente.prestation
                    "
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="
                      configurationClientToTransfertClient.vente.marchandise
                    "
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="configurationClientToTransfertClient.is_detailed"
                    color="#704ad1"
                    :value="true"
                    label="Hors taxes Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="
                      configurationClientToTransfertClient.is_detailed_ttc
                    "
                    color="#704ad1"
                    :value="true"
                    label="TTC Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="
                      configurationClientToTransfertClient.is_detailed_reste_charge
                    "
                    color="#704ad1"
                    :value="true"
                    label="Reste à charge défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <v-select
                  placeholder=""
                  :items="['text', 'image']"
                  v-model="configurationClientToTransfertClient.footer_type"
                  @change="configurationClientToTransfertClient.footer = null"
                  class="select-menu msg-error"
                  label="Type de footer"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun Type de footer trouvée"
                >
                </v-select>
              </v-col>
              <v-col
                v-if="
                  configurationClientToTransfertClient.footer_type == 'text'
                "
              >
                <v-textarea
                  outlined
                  v-model="configurationClientToTransfertClient.footer"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  :rules="[v => !!v || 'Description footer est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-textarea>
              </v-col>

              <v-col
                v-if="
                  configurationClientToTransfertClient.footer_type == 'image'
                "
              >
                <v-file-input
                  v-model="configurationClientToTransfertClient.footer"
                  counter
                  placeholder="Aucun fichier selectionné"
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  color="#704ad1"
                  item-color="#704ad1"
                  required
                  :rules="[v => !!v || 'DFichiers est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :label="
                    'Code postal' +
                      requiredP(configurationClientToTransfertClient)
                  "
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.code_postal"
                  outlined
                  required
                  :rules="[v => !!v || 'Code postal est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :label="
                    'Ville' + requiredP(configurationClientToTransfertClient)
                  "
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.ville"
                  outlined
                  required
                  :rules="[v => !!v || 'Ville est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="
                  Email
                  "
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.email"
                  required
                  type="email"
                  outlined
                  :rules="[v => !!v || 'Email est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :label="
                    'Abréviation' +
                      requiredP(configurationClientToTransfertClient)
                  "
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.abreviation"
                  required
                  outlined
                  :rules="[v => !!v || 'Abréviation est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Siren"
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.siren_value"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Tva "
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.tva_value"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Capital "
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.capital"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="RCS "
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.rcs"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Site internet"
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.site_internet"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                  placeholder=""
                  :items="uniqueArrayOfCountries"
                  v-model="configurationClientToTransfertClient.pays"
                  class="select-menu msg-error"
                  label="Pays"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun Pays trouvée"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Siret"
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.siret"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  label="Fax"
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.fax"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  placeholder=""
                  :items="computedTypeReglement"
                  v-model="configurationClientToTransfertClient.payment_type"
                  class="select-menu msg-error"
                  label="Type Règlement"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun Type Règlement trouvée"
                >
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  placeholder=""
                  :items="computedConditionPaiement"
                  v-model="
                    configurationClientToTransfertClient.payment_condition
                  "
                  class="select-menu msg-error"
                  label="Conditions du paiement"
                  :persistent-placeholder="true"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  no-data-text="Aucun Conditions du paiement trouvée"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="configurationClientToTransfertClient.telephone"
                  type="number"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Téléphone portable"
                  :persistent-placeholder="true"
                  v-model="
                    configurationClientToTransfertClient.telephone_portable
                  "
                  type="number"
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Description"
                  v-model="configurationClientToTransfertClient.description"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row
              v-if="
                configurationClientToTransfertClient.type !== 'master filiale'
              "
            >
              <span class="ml-3"> Master filiales </span>
              <div class="input-modal-champ-clients mb-0">
                <div
                  class="button-add-prop-filiale-th"
                  :class="{
                    show:
                      configurationClientToTransfertClient &&
                      configurationClientToTransfertClient.masterFiliales &&
                      configurationClientToTransfertClient.masterFiliales
                        .length == 0
                  }"
                  v-if="computedGetLastDateInList !== null"
                >
                  <v-btn
                    small
                    class="btn-add-setting mr-1"
                    @click="addNewMasterFilial('add')"
                  >
                    <font-awesome-icon icon="plus" class="mr-3 icon" /> Ajouter
                    un Master Filiale
                  </v-btn>
                </div>
                <v-row
                  class="mb-1 content-rib mb-3"
                  :class="{
                    show:
                      configurationClientToTransfertClient &&
                      configurationClientToTransfertClient.masterFiliales
                  }"
                  v-for="(master,
                  index) in configurationClientToTransfertClient.masterFiliales"
                  :key="index"
                >
                  <!-- SWIFT && IBAN  -->
                  <v-col class="input-master">
                    <v-autocomplete
                      placeholder=""
                      v-model="master.master_id"
                      :disabled="index > 0"
                      required
                      :items="computedgetMasterFilialsList"
                      :rules="[
                        v => !!v || 'Nom master filiale est obligatoire'
                      ]"
                      validate-on-blur
                      class="select-menu msg-error"
                      label="Nom master filiale *"
                      :persistent-placeholder="true"
                      item-text="name"
                      item-value="master_id"
                      outlined
                      dense
                      :auto-focus="false"
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#704ad1"
                      item-color="#704ad1"
                      no-data-text="Aucun Nom master filiale trouvée"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col class="input-master">
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="master.date_debut"
                          label="Date début"
                          :rules="[v => !!v || 'Date début est obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          outlined
                          readonly
                          clearable
                          v-on="on"
                          v-bind="attrs"
                          :persistent-placeholder="true"
                          placeholder=""
                          required
                          prepend-inner-icon="mdi-calendar"
                          color="#704ad1"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="master.date_debut"
                        no-title
                        locale="fr"
                        :disabled="index > 0"
                        :allowed-dates="date => disabledStartDate(date, index)"
                        color="#704ad1"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="input-master">
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="master.date_fin"
                          label="Date fin"
                          :rules="[v => !!v || 'Date fin est obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          outlined
                          readonly
                          clearable
                          v-on="on"
                          v-bind="attrs"
                          :persistent-placeholder="true"
                          placeholder=""
                          prepend-inner-icon="mdi-calendar"
                          color="#704ad1"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="master.date_fin"
                        no-title
                        locale="fr"
                        :disabled="index > 0"
                        :allowed-dates="date => disabledEndDate(date, index)"
                        color="#704ad1"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="input-master">
                    <v-btn
                      class="block-icon-gestion-error"
                      color="error"
                      outlined
                      small
                      @click="deleteMasterFilial(index, 'add')"
                    >
                      <font-awesome-icon icon="times" />
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-row>
            <v-row class="pt-2 pb-0">
              <v-col>
                <v-autocomplete
                  placeholder=""
                  v-model="configurationClientToTransfertClient.responsables"
                  multiple
                  chips
                  small-chips
                  :deletable-chips="true"
                  :clearable="true"
                  :items="computedChangeResponsable"
                  class="select-menu msg-error"
                  label="Support"
                  :persistent-placeholder="true"
                  item-text="full_name"
                  item-value="id"
                  return-object
                  outlined
                  no-data-text="Aucune Support trouvé"
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col>
                <v-file-input
                  label="Logo Filiale"
                  v-model="configurationClientToTransfertClient.image"
                  accept="image/*"
                  @change="previewImage"
                  counter
                  placeholder="Aucun fichier selectionné"
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row class="row-flex">
              <v-col class="config-check-societe" cols="2">
                <v-checkbox
                  label="VOLANT"
                  v-model="configurationClientToTransfertClient.volant"
                  color="#704ad1"
                  :value="true"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col v-if="configurationClientToTransfertClient.volant == true">
                <v-text-field
                  label="Responsable de dépôt"
                  :persistent-placeholder="true"
                  v-model="
                    configurationClientToTransfertClient.responsable_depot
                  "
                  outlined
                  color="#704ad1"
                  class="input-mail-commercial"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="configurationClientToTransfertClient.volant == false"
              >
                <v-select
                  placeholder=""
                  v-model="configurationClientToTransfertClient.depots"
                  multiple
                  chips
                  :small-chips="true"
                  :clearable="true"
                  :deletable-chips="true"
                  :items="computedGetDepotsTh"
                  label="Dépôt"
                  :persistent-placeholder="true"
                  return-object
                  item-text="depot"
                  item-value="id"
                  outlined
                  no-data-text="Aucune dépot trouvé"
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="configurationClientToTransfertClient.volant == false"
              >
                <div
                  v-for="depot in configurationClientToTransfertClient.depots"
                  :key="'depot' + depot.id"
                >
                  <v-text-field
                    label="Responsable de dépôt"
                    :persistent-placeholder="true"
                    v-model="depot.responsable_depot"
                    outlined
                    color="#704ad1"
                    class="input-mail-commercial"
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input
                  v-model="configurationClientToTransfertClient.files"
                  counter
                  placeholder="Aucun fichier selectionné"
                  drop-placeholder="Drop file here..."
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  color="#704ad1"
                  item-color="#704ad1"
                  multiple
                >
                  <template v-slot:selection="{ text }">
                    <v-chip label small close>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row
              class="file-header-titles mt-3"
              v-if="
                configurationClientToTransfertClient &&
                  configurationClientToTransfertClient.files &&
                  configurationClientToTransfertClient.files.length
              "
            >
              <v-col>
                <div class="file-name-title">Fichiers</div>
              </v-col>
              <v-col> <div class="file-category-title">Nom</div> </v-col>
            </v-row>
            <v-row
              class="files-to-upload"
              v-for="(file,
              index) in configurationClientToTransfertClient.files"
              :key="'file' + index"
            >
              <v-col>
                <p class="file-name">
                  <font-awesome-icon
                    icon="paperclip"
                    class="file-upload-icon"
                  />
                  {{ file.name }}
                </p>
              </v-col>
              <v-col>
                <v-text-field
                  label="Nom fichier"
                  :persistent-placeholder="true"
                  v-model="
                    configurationClientToTransfertClient.files[index].name_file
                  "
                  required
                  :rules="[v => !!v || 'Nom fichier est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleSubmitTranssfertClient"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('transfetClientModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END TRANSFERT CLIENT VERS LA GESTIONS DES SOCIETES MODAL -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import InputColorPicker from 'vue-native-color-picker';

export default {
  name: 'gestion-client-passage',
  data() {
    return {
      type: 'passage',
      searchValue: null,
      error: null,
      loading: false,
      loadingClient: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      configurationClientToAdd: {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: 'passage',
        customer: null,
        supplier: null,
        achat: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        vente: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        is_detailed: 0,
        is_detailed_ttc: 0,
        is_detailed_reste_charge: 0,
        tva: []
      },
      configurationClientToUpdate: null,
      oldConfigurationClientToUpdate: null,
      configurationClientToDeleted: null,
      configurationClientToTransfertClient: null,
      configurationClientToTransfertClientOld: null,
      num_tva_siren: ['Numéro TVA', 'SIREN'],
      uniqueArrayOfCountries: [],
      month_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      optionsTypeSociete: [
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Organisme', value: 'organisme' },
        { text: 'SCI', value: 'sci' },
        { text: 'Master Filiale', value: 'master filiale' }
      ],
      mails: [],
      addClientModal: false,
      updateClientModal: false,
      deletedClientModal: false,
      transfetClientModal: false,
      selectedTable: []
    };
  },
  methods: {
    ...mapActions([
      'fetchAllTableClient',
      'addNewClientPassage',
      'deleteClientPassage',
      'updateClientPassage',
      'getAllCountreies',
      'getAllTypeSocieteSupport',
      'fetchAllTypeSociete',
      'getAllConditionsPaiement',
      'getAllTypesReglement',
      'fetchDepotsTh',
      'fetchResponsableFilialeFreeTh',
      'getAllMasterFilials',
      'updateSettingFilialeTh',
      'errorManagerFiliale'
    ]),
    openModalAjouterClient() {
      this.addClientModal = true;
    },
    async checkFilialeMail(email) {
      const response = await this.errorManagerFiliale({ email: email });
      if (response.success) {
        this.mails = response.response;
      }
    },
    previewImage: function(event) {
      var input = event ? event.target : null;
      if (input && input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = () => {
          this.configurationClientToTransfertClient.imageData = input.files[0];
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    disabledStartDate(date, index) {
      if (
        this.configurationClientToTransfertClient?.masterFiliales[index + 1]
          ?.date_fin
      ) {
        return (
          this.configurationClientToTransfertClient.masterFiliales[index + 1]
            .date_fin &&
          moment(date, 'YYYY-MM-DD') <
            new Date(
              this.configurationClientToTransfertClient.masterFiliales[
                index + 1
              ].date_fin
            )
        );
      }
      return true;
    },
    disabledEndDate(date, index) {
      if (
        this.configurationClientToTransfertClient?.masterFiliales[index]
          ?.date_debut
      ) {
        return (
          this.configurationClientToTransfertClient.masterFiliales[index]
            .date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') >
            new Date(
              this.configurationClientToTransfertClient.masterFiliales[
                index
              ].date_debut
            )
        );
      }
      return true;
    },
    addNewMasterFilial(type) {
      if (type === 'add') {
        this.configurationClientToTransfertClient?.masterFiliales?.unshift({
          master_id: null,
          date_debut: null,
          date_fin: null
        });
      }
    },
    deleteMasterFilial(index, type) {
      if (type === 'add') {
        this.configurationClientToTransfertClient.masterFiliales.splice(
          index,
          1
        );
      }
    },
    onChangeSupport(checked) {
      if (
        checked === true &&
        this.configurationClientToTransfertClient.responsables &&
        this.configurationClientToTransfertClient.responsables.length
      ) {
        this.configurationClientToTransfertClient.responsables = this.configurationClientToTransfertClient.responsables.filter(
          item => item.type === 'responsable_support'
        );
      }
    },
    showTransfertClient(item) {
      this.getAllTypeSocieteSupport();
      this.fetchAllTypeSociete();
      this.getAllConditionsPaiement();
      this.getAllTypesReglement();
      this.fetchDepotsTh('no');
      this.fetchResponsableFilialeFreeTh();
      this.getAllMasterFilials();
      this.configurationClientToTransfertClient = { ...item };
      this.configurationClientToTransfertClientOld = item;
      this.configurationClientToTransfertClient.type = 'professionnel';
      this.transfetClientModal = true;
    },
    async handleSubmitTranssfertClient() {
      if (this.$refs.formTransfert.validate()) {
        this.error = null;
        let ArrayOfStartDate = this.configurationClientToTransfertClient.masterFiliales.filter(
          item => item.date_debut === null
        );
        if (ArrayOfStartDate.length) {
          this.error = 'Date début des masters filiales obligatoire';
          return;
        }
        this.loading = true;
        const response = await this.updateSettingFilialeTh({
          filiale: this.configurationClientToTransfertClient,
          oldFiliale: this.configurationClientToTransfertClientOld,
          depot: [],
          dejaVolant: false
        });

        if (response.success) {
          this.error = null;
          this.loading = false;
          this.hideModal('transfetClientModal');
          this.handleFilter();
          await this.fetchDepotsTh('no');
        } else {
          this.error = response.error;
          this.loading = false;
        }
      }
    },
    showUpdateClient(item) {
      this.updateClientModal = true;
      this.configurationClientToUpdate = { ...item };
      this.oldConfigurationClientToUpdate = item;
    },
    showDeleteClient(item) {
      this.configurationClientToDeleted = item;
      this.deletedClientModal = true;
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.handleFilter();
    },
    hideModal(ref) {
      this[ref] = false;
      this.resetModal();
      if (ref == 'addClientModal') {
        this.$refs.form.reset();
      }
      if (ref == 'updateClientModal') {
        this.$refs.formUpdate.reset();
      }
      if (ref == 'transfetClientModal') {
        this.$refs.formTransfert.reset();
      }
    },
    setLocalStorageParamsGestionClientPassage() {
      localStorage.setItem(
        'Params-gestion-client-passage',
        JSON.stringify({
          type: this.type,
          search: this.searchValue
        })
      );
    },
    async handleFilter() {
      await this.fetchAllTableClient({
        per_page: this.perPage,
        page: this.page,
        type: this.type,
        search: this.searchValue
      });
    },
    async changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageParamsGestionClientPassage();
    },
    resetModal() {
      this.configurationClientToAdd = {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: this.type,
        customer: null,
        supplier: null,
        achat: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        vente: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        is_detailed: 0,
        is_detailed_ttc: 0,
        is_detailed_reste_charge: 0,

        tva: []
      };
      this.configurationClientToUpdate = {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: this.type,
        customer: null,
        supplier: null,
        achat: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        vente: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        is_detailed: 0,
        is_detailed_ttc: 0,
        is_detailed_reste_charge: 0,

        tva: []
      };
      this.configurationClientToDeleted = null;
      this.configurationClientToTransfertClient = {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: this.type,
        customer: null,
        supplier: null,
        achat: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        vente: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        is_detailed: 0,
        is_detailed_ttc: 0,
        is_detailed_reste_charge: 0,
        tva: []
      };
      this.configurationClientToTransfertClientOld = null;
      this.error = null;
      this.mails = [];
    },
    handleSubmitClient() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.addNewClientPassage(this.configurationClientToAdd)
          .then(response => {
            if (response && response.error) {
              this.error = response.error;
              this.loading = false;
            }
            if (response.success) {
              this.error = null;
              this.loading = false;
              this.hideModal('addClientModal');
            }
          })
          .catch();
      }
    },
    async handleUpdateClient() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        const response = await this.updateClientPassage({
          oldConfigurationClientToUpdate: this.oldConfigurationClientToUpdate,
          configurationClientToUpdate: this.configurationClientToUpdate
        });
        if (response.success) {
          this.error = null;
          this.loading = false;
          this.hideModal('updateClientModal');
        } else {
          this.error = response.error;
          this.loading = false;
        }
      }
    },
    async handleDeleteConfClient() {
      this.loading = true;
      const response = await this.deleteClientPassage(
        this.configurationClientToDeleted
      );
      if (response) {
        this.loading = false;
        this.hideModal('deletedClientModal');
      }
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'getListClientPassages',
      'getClientIsError',
      'getClientlsLoading',
      'getTypeSociete',
      'getPaysList',
      'getTotalRowsPassages',
      'TypeReglement',
      'ConditionPaiement',
      'getResponsableFilialeFreeTh',
      'getTypeSocieteSupportTh',
      'getDepotsTh',
      'getMasterFilialeList'
    ]),
    totalPages() {
      if (this.getTotalRowsPassages) {
        return Math.ceil(this.getTotalRowsPassages / this.perPage);
      }
      return this.getTotalRowsPassages;
    },
    computedgetMasterFilialsList() {
      let newListMasterFilials = [];
      newListMasterFilials = this.getMasterFilialeList.map(item => {
        return { name: item.name, master_id: item.id };
      });
      return newListMasterFilials;
    },
    computedGetDepotsTh() {
      return this.getDepotsTh;
    },
    computedChangeResponsable() {
      return this.getResponsableFilialeFreeTh.map(item => {
        return { ...item, name: item.full_name };
      });
      // if (this.filialeToAdd.is_societe_support === false) {
      //   return this.getResponsableFilialeFreeTh.map(item => {
      //     return { ...item, name: item.full_name };
      //   });
      // } else {
      //   return this.getResponsableFilialeFreeTh
      //     .map(item => {
      //       return { ...item, name: item.full_name };
      //     })
      //     .filter(item => item.type === 'responsable_support');
      // }
    },
    computedGetLastDateInList() {
      return (
        this.configurationClientToTransfertClient &&
        this.configurationClientToTransfertClient.masterFiliales &&
        this.configurationClientToTransfertClient.masterFiliales[0] &&
        this.configurationClientToTransfertClient.masterFiliales[0].date_fin
      );
    },
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return table;
    },
    computedTypeReglement() {
      var table = this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return [{ text: '', value: null }, ...table];
    },
    requiredP() {
      return function(data) {
        return data && data.type == 'particulier' ? '' : '*';
      };
    },
    computedListeYear: function() {
      var courantYear = moment().year();
      let year = [];
      for (var i = 2019; i <= courantYear + 1; i++) {
        year.push({ value: i, text: i });
      }
      return year;
    },
    computedDaysOfMonth() {
      return function(month) {
        var result = [];
        if (month != null) {
          var courantYear = moment().year();
          var resultday = moment(
            courantYear + '-' + month,
            'YYYY-MM'
          ).daysInMonth();
          result = [...Array(resultday - 1 + 1).keys()].map(x => x + 1);
        }
        return result;
      };
    },
    computedFields() {
      return [
        {
          value: 'name',
          text: 'Nom'
        },
        {
          value: 'email',
          text: 'Email'
        },
        {
          value: 'adresse',
          text: 'Adresse'
        },
        {
          value: 'code_postal',
          text: 'Code postal'
        },
        {
          value: 'pays',
          text: 'Pays'
        },
        {
          value: 'ville',
          text: 'ville'
        },
        {
          value: 'telephone_portable',
          text: 'Téléphone portable'
        },
        {
          value: 'Actions',
          text: 'Actions'
        }
      ];
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue'),
    'v-input-colorpicker': InputColorPicker
  },

  async mounted() {
    this.handleFilter();
    await this.getAllCountreies();
    let countryNames = [];
    if (this.getPaysList && this.getPaysList.length) {
      this.getPaysList?.map(element => countryNames.push(element.nom));
      this.uniqueArrayOfCountries = [...new Set(countryNames)];
    }
  }
};
</script>
<style lang="scss">
.config-check-societe {
  .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}
</style>
<style scoped lang="scss">
.card-style {
  border-radius: 10px;
  border: 1px solid #b5b5b5;
  padding: 28px;
  margin-top: 16px;
  margin-bottom: 20px;
  position: relative;

  .title-card {
    position: absolute;
    top: -12px;
    left: 14px;
    font-size: 12px;
    background-color: #fff;
    padding-left: 5px;
    color: rgb(0 0 0 / 41%);
  }
}
.table-gestion-content {
  max-height: calc(100vh - 253px) !important;
  height: calc(100vh - 268px) !important;
}
.input-modal-champ-clients {
  margin: auto;
  // display: flex;
  // align-items: center;
  width: 98%;
  padding: 8px 10px 8px 5px;
  border: 1px solid #9e9e9e;
  .button-add-prop-filiale-th {
    &.show {
      float: left !important;
    }
  }
  .content-rib {
    .input-master {
      margin-bottom: -31px !important;
    }
    &.show {
      margin: 2px 2px 0px 4px;
    }
  }
}
.block-icon-gestion-error {
  box-shadow: none !important;
  padding: 6.5px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  padding: 0px 5px;
  cursor: pointer;
  height: 32px !important;
  width: 32px !important;
  min-width: 29px !important;
  z-index: 1;
}
.color_picker_width {
  width: 32px;
  height: 32px;
  border: solid 1px;
  margin: auto;
  display: block;
}
</style>
